import './bootstrap.js';

import '../laravel-cms/js/app.js'
import '../laravel-core/js/front/app.js'

import '../laravel-core/js/tarteaucitron/tarteaucitron.import.js';

import.meta.glob([
    '../medias/images/**/*.jpg',
    '../medias/images/**/*.png',
    '../medias/images/**/*.svg',
    '../fonts/**',
]);

import '../scss/app.scss';

// Code site

import './creative-menu/script.js';
import './creative-menu/style.css';

import './slick/slick.min.js';
import './slick/script.js';
import './slick/slick.css';
import './slick/slick-theme.css';

import './bootstrap/bootstrap-input-spinner.js';

import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
import './jquery.fancybox/style.css';

import './jquery.counterup/jquery.counterup.min.js';

jQuery(function($) {
	//
	// js pour le compteur
	//
	const counterUp = window.counterUp.default;

	const callback = entries => {
		entries.forEach( entry => {
			const el = entry.target;

			if(entry.isIntersecting) {
				counterUp(el, {
					delay: 10,
					duration: 1000,
				});
			}
		} )
	}

	$('.compteur').each(function() {
		const IO = new IntersectionObserver(
			callback,
			{ threshold: 1 }
		);

		IO.observe(this);
	});


	//
	// js pour mettre à jour les input[type="number"]
	//
	$('input[type="number"]').inputSpinner();


	//
	// js pour modifier le comportement par défaut de fancybox
	//
	$('[data-fancybox]').fancybox({
		animationEffect: 'zoom-in-out',
		backFocus: false,
		loop: true,
		transitionEffect: 'slide'
	});
});